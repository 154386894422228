/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
/* eslint-disable max-lines */
import { MouseEvent, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ProductType } from 'Component/Product/Product.config';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import CartDispatcher from 'Store/Cart/Cart.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { ReactElement } from 'Type/Common.type';
import {
    getMaxQuantity,
    getMinQuantity,
    getName,
    getProductInStock,
} from 'Util/Product/Extract';
import {
    ProductTransformData, StockCheckProduct,
} from 'Util/Product/Product.type';
import { RootState } from 'Util/Store/Store.type';

import AddToCartVariants from './AddToCartVariants.component';
import {
    AddToCartVariantsComponentContainerPropKeys,
    AddToCartVariantsComponentProps,
    AddToCartVariantsContainerMapDispatchProps,
    AddToCartVariantsContainerMapStateProps,
    AddToCartVariantsContainerProps,
    AddToCartVariantsContainerState,
} from './AddToCartVariants.type';

/** @namespace VusaApp/Component/AddToCartVariants/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): AddToCartVariantsContainerMapStateProps => ({
    cartId: state.CartReducer.cartTotals?.id || '',
});

/** @namespace VusaApp/Component/AddToCartVariants/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): AddToCartVariantsContainerMapDispatchProps => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    fallbackAddToCartVariants: (options) => CartDispatcher.addProductToCart(dispatch, options),
});

/* @namespace VusaApp/Component/AddToCartVariants/Container */
// eslint-disable-next-line max-len
export class AddToCartVariantsContainer extends PureComponent<AddToCartVariantsContainerProps, AddToCartVariantsContainerState> {
    static defaultProps: Partial<AddToCartVariantsContainerProps> = {
        quantity: 1,
        cartId: '',
        mix: {},
        layout: CategoryPageLayout.GRID,
        isIconEnabled: true,
        isDisabled: false,
        addToCart: undefined,
        updateSelectedValues: undefined,
        withLink: false,
        product: {},
    };

    containerFunctions = {
        addProductToCart: this.addProductToCart.bind(this),
        handleButtonClick: this.handleButtonClick.bind(this),
    };

    state: AddToCartVariantsContainerState = {
        isAdding: false,
    };

    globalValidationMap = [
        this.validateStock.bind(this),
        this.validateQuantity.bind(this),
        this.validateCustomizable.bind(this),
        this.validateByType.bind(this),
    ];

    typeValidationMap = {
        [ ProductType.BUNDLE ]: this.validateBundle.bind(this),
        [ ProductType.DOWNLOADABLE ]: this.validateDownloadable.bind(this),
        [ ProductType.CONFIGURABLE ]: this.validateConfigurable.bind(this),
        [ ProductType.GROUPED ]: this.validateGroup.bind(this),
    };

    handleButtonClick(e: MouseEvent): void {
        const { withLink } = this.props;

        // Prevent container Link from triggering redirect
        if (!withLink) {
            e.stopPropagation();
            e.preventDefault();
        }

        this.addProductToCart();
    }

    async addProductToCart(): Promise<void> {
        // @ts-ignore
        if ((!window.productQuantities || Object.keys(window.productQuantities).length === 0)) {
            return;
        }

        this.setState({ isAdding: true });

        const {
            cartId,
            fallbackAddToCartVariants,
        } = this.props;
            // @ts-ignore
        const magentoProduct = this.magentoProductTransform(window.productQuantities);

        try {
            await fallbackAddToCartVariants({
                products: magentoProduct,
                cartId,
            });
        } finally {
            this.setState({ isAdding: false });
        }

        this.setState({ isAdding: false });
    }

    magentoProductTransform = (
        products: ProductTransformData[],
    ): ProductTransformData[] => {
        const productData: ProductTransformData[] = [];
        const items = Object.keys(products);
        console.log('products', products);
        items.forEach((product) => {
            productData.push(
                {
                    sku: products[Number(product)].sku,
                    quantity: Number(products[Number(product)].quantity),
                    selected_options: products[Number(product)].selected_options,
                    entered_options: products[Number(product)].entered_options,
                },
            );
        });

        return productData;
    };

    validate(): boolean {
        let isValid = true;

        this.globalValidationMap.forEach((step) => {
            if (!step()) {
                isValid = false;
            }
        });

        return isValid;
    }

    validateStock(): boolean {
        const { product, showNotification } = this.props;
        const inStock = getProductInStock(product as Partial<StockCheckProduct>);

        if (!inStock) {
            const name = getName(product);

            showNotification(NotificationType.INFO, __('Sorry! The product %s is out of stock!', name));
        }

        return inStock;
    }

    validateQuantity(): boolean {
        const {
            product, quantity, showNotification, product: { type_id: typeId },
        } = this.props;
        const minQty = getMinQuantity(product);
        const maxQty = getMaxQuantity(product);
        const inRange = quantity >= minQty && quantity <= maxQty;
        const isValid = typeId === ProductType.GROUPED || inRange;

        if (!isValid) {
            if (minQty > maxQty) {
                showNotification(NotificationType.INFO, __('The requested qty is not available!'));
            } else if (quantity < minQty) {
                showNotification(NotificationType.INFO, __('Sorry! Minimum quantity for this product is %s!', minQty));
            } else if (quantity > maxQty) {
                showNotification(NotificationType.INFO, __('Sorry! Maximum quantity for this product is %s!', maxQty));
            }

            this.setState({ isAdding: false });

            return false;
        }

        return isValid;
    }

    validateByType(): boolean {
        const { product: { type_id = '' } = {} } = this.props;
        const { [type_id as keyof typeof this.typeValidationMap]: typeValidationFn } = this.typeValidationMap;

        if (!typeValidationFn) {
            return true;
        }

        return typeValidationFn();
    }

    validateBundle(): boolean {
        return true;
    }

    validateCustomizable(): boolean {
        return true;
    }

    validateDownloadable(): boolean {
        return true;
    }

    validateGroup(): boolean {
        return true;
    }

    validateConfigurable(): boolean {
        return true;
    }

    containerProps(): Pick<AddToCartVariantsComponentProps, AddToCartVariantsComponentContainerPropKeys> {
        const {
            isDisabled,
            isIconEnabled,
            mix,
            layout,
        } = this.props;

        const {
            isAdding,
        } = this.state;

        return {
            isDisabled,
            isIconEnabled,
            mix,
            layout,
            isAdding,
        };
    }

    render(): ReactElement {
        return (
            <AddToCartVariants
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartVariantsContainer);
